import React, { useState } from "react"
import PropTypes from "prop-types"
import { Slide, ToastContainer } from "react-toastify"

// Other components imports
import Footer from "./footer"
import Header from "./header"
import Notifications from "./Notifications"

// Redux imports

// Static imports
import "./bootstrap.min.css"
import "react-toastify/dist/ReactToastify.css"
import "./layout.css"

const Layout = ({
  children,
  topTransparent,
  noHeader,
  noFooter,
  bgColor,
  displayForTransparentHeader,
}) => {
  const [displayNotif, setDisplayNotif] = useState(false)

  return (
    <>
      {noHeader ? (
        <></>
      ) : (
        <Header
          topTransparent={topTransparent}
          setDisplayNotif={setDisplayNotif}
          displayForTransparent={displayForTransparentHeader}
        />
      )}

      <Notifications
        displayNotif={displayNotif}
        setDisplayNotif={setDisplayNotif}
      />
      <div id="all-wrapper">
        <main style={{ minHeight: "100vh", background: bgColor || "none" }}>
          {children}
        </main>
        {noFooter ? <></> : <Footer />}
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        transition={Slide}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  topTransparent: false,
  noFooter: false,
  displayForTransparentHeader: "block",
}

export default Layout
