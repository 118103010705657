import styled from "styled-components"

export const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  padding-top: 1px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.5s;

  .navbar-nav .nav-link {
    transition: color 0.5s;
  }

  .nav-search input {
    background-color: white;
  }

  &.header-white {
    background-color: #ffffff;

    .navbar-nav .nav-link {
      color: black;
    }
  }

  &.header-transparent {
    background-color: transparent;

    .navbar-nav .nav-link {
      color: white;
    }

    .nav-search {
      display: none;
    }

    .nav-search input {
      background-color: transparent;
    }
  }

  .navbar-brand {
    width: 175px;
    margin-left: 15px;
  }

  .navbar-nav {
    align-items: center;
  }

  .navbar-nav .nav-link {
    padding: 0.75rem 1rem;
  }

  .navbar-nav .nav-search {
    padding: 0.25rem 1rem;
    margin-right: 20px;
  }

  .nav-search input {
    transition: background-color 0.5s;
  }

  .nav-links-resp {
    display: flex;
    align-items: center;
  }

  .dropdown-item {
    cursor: pointer;
  }

  .dropdown-item:focus {
    /* background: #f4b71a !important;
    color: white !important; */
  }

  .navbar-nav .nav-search:hover {
    color: black;
  }

  .notif-and-profile {
    display: flex;
    align-items: center;
  }

  .notifikasi-icon {
    width: 30px;
    margin-right: 12px;
    margin-left: 6px;
    cursor: pointer;
  }

  .photo-icon {
    width: 30px;
    margin-right: -35px;
    cursor: pointer;
  }

  .profile-dropdown {
    max-width: 194px;
    height: 48px;
  }

  .profile-dropdown > .nav-link {
    padding-left: 50px;
  }

  .nav-search {
    .search-logo-wrap {
      position: absolute;
      display: inline-block;
    }

    img {
      width: 30px;
      position: relative;
      left: 9px;
      top: 2px;
    }

    input {
      width: calc(95vw - 720px);
      color: black;
      background-color: #f9fafb;
      border: 1px solid rgb(0, 0, 0, 0.5);
      padding-top: 6px;
      padding-bottom: 4px;
      padding-right: 12px;
      padding-left: 44px;
      margin-top: -4px;
      margin-bottom: -4px;
    }
  }

  .mobile-only {
    display: none;
  }

  @media only screen and (max-width: 991.98px) {
    &.header-transparent {
      .navbar-light .navbar-toggler-icon {
        background-image: url("/content/hamburger-white.svg");
      }

      .navbar-light .navbar-toggler {
        color: white;
        border-color: rgba(255, 255, 255, 0.5);
      }
    }

    .navbar {
      padding-bottom: 0;
    }

    .navbar-nav {
      align-items: flex-start;
      padding-top: 8px;
    }

    .navbar-nav .nav-search {
      display: none;
    }

    .nav-search {
      img {
        left: 9px;
        top: 6px;
      }

      input {
        width: 100%;
        padding: 6px 12px;
        padding-left: 44px;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .mobile-only {
      display: block;
    }

    .nav-links-resp {
      flex-direction: column;
    }

    .notifikasi-icon {
      margin-left: 0.75rem;
    }
  }
`

export const AboveStyledHeader = styled.div`
  .top-not-transparent {
    height: 65px;
  }

  @media only screen and (max-width: 991.98px) {
    .top-not-transparent {
      height: 105px;
    }
  }
`
