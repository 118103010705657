// Generic imports
import React, { useState, useEffect, useRef } from "react"
import { Link, navigate } from "gatsby"
import { connect } from "react-redux"

// Other components imports
import Image from "./image"
import { StyledHeader, AboveStyledHeader } from "./headerCSS"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"

// Redux imports
import * as sessionSelectors from "../redux/slices/session/selectors"
import * as sessionThunks from "../redux/slices/session/thunks"

// Static imports
import searchLogo from "../images/common/search.png"
import { BiBell } from "@react-icons/all-files/bi/BiBell"
import { VscAccount } from "@react-icons/all-files/vsc/VscAccount"

// Utils imports
import { getParameterByName, getQueryVariable } from "../services/utils"
import Button from "./Reusable/Button"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
  isAuthenticated: state.session.isAuthenticated,
})

const mapDispatchToProps = {
  logout: sessionThunks.logout,
  checkMyself: sessionThunks.checkMyself,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const Header = props => {
  const {
    user,
    logout,
    topTransparent,
    setDisplayNotif,
    checkMyself,
    isAuthenticated,
    displayForTransparent,
  } = props

  const [toSearch, setToSearch] = useState("")
  const [isWhite, setIsWhite] = useState(false)
  const ref = useRef(null)

  const handleScroll = () => {
    if (ref.current && topTransparent) {
      if (!(ref.current.getBoundingClientRect().top < 0)) {
        setIsWhite(false)
      } else if (ref.current.getBoundingClientRect().top < 0) {
        setIsWhite(true)
      }
    }
  }

  useEffect(() => {
    if (getParameterByName("q")) {
      setToSearch(getParameterByName("q"))
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    if (user.email) {
      checkMyself()
    }
  }, [isAuthenticated])

  const navSearch = (
    <div className="nav-link nav-search">
      <div className="search-logo-wrap">
        <Link
          to={`/search?q=${toSearch}${
            getQueryVariable("cat") ? `&cat=${getQueryVariable("cat")}` : ""
          }`}
        >
          <img src={searchLogo} />
        </Link>
      </div>
      <input
        value={toSearch}
        placeholder="Mau belajar apa hari ini?"
        onChange={e => {
          setToSearch(e.target.value)
        }}
        onKeyDown={e => {
          if (e.key === "Enter") {
            navigate(
              `/search?q=${toSearch}${
                getQueryVariable("cat") ? `&cat=${getQueryVariable("cat")}` : ""
              }`
            )
          }
        }}
      />
    </div>
  )

  return (
    <AboveStyledHeader>
      <StyledHeader
        className={
          !topTransparent || isWhite ? "header-white" : "header-transparent"
        }
      >
        <Navbar expand="lg">
          <Navbar.Brand>
            <Link to="/">
              {!topTransparent || isWhite ? (
                <Image imgName="purwalenta_logo.png" alt="Logo normal" />
              ) : (
                <Image imgName="purwalenta_white.png" alt="Logo white" />
              )}
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              {navSearch}
              {user.is_tutor ? (
                ""
              ) : (
                <Link className="nav-link" to="/menjadi-tutor">
                  Menjadi Tutor
                </Link>
              )}

              <Link className="nav-link" to="/search">
                List Kursus
              </Link>

              {user.email ? (
                <>
                  <div className="nav-links-resp" />
                  <div className="notif-and-profile">
                    <div
                      className="notifikasi-icon"
                      style={{
                        fontSize: "1.5rem",
                        color: `${
                          !topTransparent || isWhite ? "black" : "white"
                        }`,
                      }}
                      onClick={() => setDisplayNotif(true)}
                    >
                      <BiBell />
                    </div>
                    <div
                      className="photo-icon"
                      style={{
                        fontSize: "1.5rem",
                        color: `${
                          !topTransparent || isWhite ? "black" : "white"
                        }`,
                      }}
                    >
                      <VscAccount />
                    </div>
                    <NavDropdown
                      title={
                        user.trimmedFullname ? user.trimmedFullname : "No Name"
                      }
                      className="dib profile-dropdown"
                    >
                      <Link className="dropdown-item" to="/dashboard/courses/">
                        Dashboard
                      </Link>
                      {user.is_tutor && (
                        <Link className="dropdown-item" to="/dashboard/tutor/">
                          Dashboard Tutor
                        </Link>
                      )}
                      <div
                        className="dropdown-item"
                        onClick={() => {
                          logout()
                        }}
                      >
                        Logout
                      </div>
                    </NavDropdown>
                  </div>
                </>
              ) : (
                <>
                  <div className="nav-links-resp">
                    <div>
                      <Button to="/login" className="secondary">
                        Masuk
                      </Button>
                      <Button to="/register">Daftar</Button>
                    </div>
                  </div>
                  <div className="notif-and-profile" />
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="mobile-only">{navSearch}</div>
      </StyledHeader>
      <div
        ref={ref}
        className={topTransparent ? "" : "top-not-transparent"}
        style={{
          display: `${displayForTransparent}`,
          position: "relative",
          top: "60px",
        }}
      />
    </AboveStyledHeader>
  )
}

export default withConnect(Header)
