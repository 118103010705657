import React from "react"
import Drawer from "@material-ui/core/Drawer"

import { connect } from "react-redux"

import * as sessionSelectors from "../../redux/slices/session/selectors"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const dummy = [
  // {
  //   title: "Kursus telah berakhir",
  //   message: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt volutpat."
  // },
  // {
  //   title: "Anda diundang ke dalam kursus Menggambar Monster",
  //   message: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt volutpat."
  // }
]

const printNotifikasi = list => {
  if (list.length === 0) {
    return <div>Tidak ada notifikasi</div>
  } else {
    return list.map((obj, index) => (
      <div key={index}>
        <h5>{obj.title}</h5>
        <p>{obj.message}</p>
        <hr />
      </div>
    ))
  }
}

function Notifications({ user, displayNotif, setDisplayNotif }) {
  return (
    <div>
      <Drawer
        anchor="right"
        open={displayNotif}
        onClose={() => setDisplayNotif(false)}
      >
        <div className="user-notifikasi-drawer">
          <h3>Notifikasi</h3>
          <hr />
          {printNotifikasi(dummy)}
        </div>
      </Drawer>
    </div>
  )
}

export default withConnect(Notifications)
