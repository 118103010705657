/* eslint-disable camelcase */
import { navigate } from "gatsby"
import { toast } from "react-toastify"
import {
  loginSuccess,
  logoutSuccess,
  logoutFailed,
  updateAccount,
  resetAccount,
} from "."
import * as authApi from "../../../services/auth"
import { setAuthToken } from "../../../services/auth"

export function login(data) {
  return async (dispatch, _getState) => {
    try {
      dispatch(resetAccount())
      const resp = await authApi.login(data)
      if (resp.data?.message === "User/Passowrd Salah") {
        toast.error("User/Passowrd Salah")
      } else if (typeof resp.data === "string") {
        toast.error(resp.data)
      } else if (resp.data?.key) {
        setAuthToken(resp.data.key)
        const profileResp = await authApi.me()
        dispatch(loginSuccess({ ...resp.data, ...profileResp.data }))
        toast.success("Login Success!")
      } else {
        throw new Error()
      }
    } catch (error) {
      toast.error("Error! Please try again later")
    }
  }
}

export function logout() {
  return async (dispatch, _getState) => {
    try {
      await authApi.logout()
      dispatch(logoutSuccess())
      setAuthToken()
    } catch (error) {
      dispatch(logoutFailed())
    } finally {
      dispatch(resetAccount())
      toast.success("Logout Success!")
      setTimeout(() => {
        navigate("/")
      }, 2000)
    }
  }
}

export function loginWithGoogle(data) {
  return async (dispatch, _getState) => {
    try {
      dispatch(resetAccount())
      const resp = await authApi.loginGoogle(data)
      if (resp.data?.message === "User/Passowrd Salah") {
        toast.error("User/Passowrd Salah")
      } else if (typeof resp.data === "string") {
        toast.error(resp.data)
      } else if (resp.data?.key) {
        setAuthToken(resp.data.key)
        const profileResp = await authApi.me()
        dispatch(loginSuccess({ ...resp.data, ...profileResp.data }))
        toast.success("Login Success!")
      } else {
        throw new Error()
      }
    } catch (error) {
      toast.error("Error! Please try again later")
    }
  }
}

export const register = data => {
  return async (dispatch, _getState) => {
    try {
      dispatch(resetAccount())
      const resp = await authApi.register(data)
      if (resp.data?.key) {
        setAuthToken(resp.data.key)
        const me = await authApi.updateProfile({
          display_name: data.display_name,
          phone: data.phone,
          dob: data.dob,
        })
        dispatch(loginSuccess({ ...resp.data, ...me.data }))
        toast.success("Register Success!")
      }
    } catch (error) {
      const msg = error?.response?.data
      msg
        ? toast.error(msg[Object.keys(msg)[0]][0])
        : toast.error("Register Failed")
      throw new Error()
    }
  }
}

// no redux
export function editProfile(data) {
  return async (dispatch, _getState) => {
    try {
      await authApi.updateProfile(data)
      dispatch(checkMyself())
      toast.success("Profile Updated!")
    } catch (error) {
      toast.error(error.response?.data)
      return error.response?.data
    }
  }
}

export function checkMyself() {
  return async (dispatch, _getState) => {
    if (_getState().session.isAuthenticated) {
      try {
        const resp = await authApi.me()
        dispatch(updateAccount(resp.data))
      } catch (err) {
        if (err.response?.data?.detail === "Invalid token.") {
          dispatch(resetAccount())
          setAuthToken()
          toast.error("Your session has expired. Please login again")
        }
      }
    }
  }
}
