// Generic imports
import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Image from "./image"

const Styles = styled.footer`
  .footer-grid {
    display: flex;
    padding: 16px;
    padding-top: 12px;
    max-width: 1300px;
    margin: auto;
  }

  .footer-first-col,
  .footer-second-col,
  .footer-third-col,
  .footer-fourth-col {
    padding: 8px;
  }

  .footer-first-col {
    width: 25%;

    .footer-brand {
      width: 200px;
      display: block;
    }

    .footer-desc {
      padding-left: 40px;
      padding-top: 20px;
      color: #a0a0a0;
    }
  }

  .footer-second-col,
  .footer-third-col {
    h4 {
      padding-top: 45px;
    }

    a {
      display: block;
      padding-top: 10px;
      color: #a0a0a0;
    }
  }

  .footer-second-col {
    width: 20%;
  }

  .footer-third-col {
    width: 25%;

    img {
      width: 100%;
      max-width: 150px;
    }

    .sosmed-list {
      display: flex;

      a {
        width: 60px;
        padding: 4px;
      }

      a:first-child {
        padding-left: 0;
        padding-right: 8px;
      }

      a:last-child {
        padding-left: 8px;
        padding-right: 0;
      }
    }
  }

  .footer-fourth-col {
    width: 30%;

    .footer-bg-img {
      width: 100%;
    }

    .play-app-store {
      display: flex;
    }

    .footer-playstore-img {
      width: 45%;
      margin: auto;
    }

    .footer-appstore-img {
      width: 50.5%;
      margin: auto;
    }
  }

  .copyright {
    padding: 24px;
    padding-top: 0;
    max-width: 1300px;
    margin: auto;
  }

  @media only screen and (max-width: 1200px) {
    .footer-first-col {
      .footer-desc {
        padding-left: 0;
      }
    }
  }

  @media only screen and (max-width: 991.98px) {
    .footer-grid {
      flex-wrap: wrap;
      justify-content: center;
    }

    .footer-first-col,
    .footer-second-col,
    .footer-third-col {
      width: 33%;
    }

    .footer-fourth-col {
      width: 75%;
      max-width: 450px;
    }
  }

  @media only screen and (max-width: 767.98px) {
    .footer-second-col,
    .footer-third-col {
      h4 {
        padding-top: 15px;
      }
    }

    .footer-first-col,
    .footer-second-col {
      width: 50%;
    }

    .footer-third-col {
      width: 100%;
      padding-bottom: 10px;

      .temukan-kami {
        padding-top: 25px;
      }
    }
  }

  @media only screen and (max-width: 511.98px) {
    .footer-first-col,
    .footer-second-col {
      width: 100%;
    }

    .footer-fourth-col {
      width: 100%;
    }
  }
`

const Footer = () => (
  <Styles>
    <hr />
    <div className="footer-grid">
      <div className="footer-first-col">
        <Link to="/" className="footer-brand">
          <Image imgName="purwalenta_logo.png" alt="Logo" />
        </Link>
        <div className="footer-desc">
          <div>Pesona Khayangan Blok CK No.41</div>
          <div>Depok, Jawa Barat</div>
          <div>Indonesia</div>
        </div>
        <div className="footer-desc">
          <div>Phone:</div>
          <div>+62 813 8373 6364</div>
        </div>
        <div className="footer-desc">
          <div>Email:</div>
          <div>hai@purwalenta.com</div>
        </div>
      </div>
      <div className="footer-second-col">
        <h4>Useful Links</h4>
        <Link to="/">Home</Link>
        <Link to="/tentang-kami">Tentang Kami</Link>
        <Link to="#">Daftar Event</Link>
        <Link to="#">Cara Penggunaan</Link>
        <Link to="/terms-and-conditions">Syarat &amp; Ketentuan</Link>
        <Link to="/privacy-policy">Kebijakan Privasi</Link>
      </div>
      <div className="footer-third-col">
        <h4 className="temukan-kami">Temukan Kami Di Sini</h4>
        <div className="sosmed-list">
          <a
            href="https://www.instagram.com/purwalenta/"
            target="_blank"
            rel="external nofollow noopener noreferrer"
          >
            <Image imgName="sosmed-instagram.png" />
          </a>
          <a
            href="https://www.youtube.com/c/Purwalenta"
            target="_blank"
            rel="external nofollow noopener noreferrer"
          >
            <Image imgName="sosmed-youtube.png" />
          </a>
        </div>
      </div>
      <div className="footer-fourth-col">
        <div className="footer-bg-img">
          <Image imgName="footer-img.png" alt="footer-background" />
        </div>
        <div className="play-app-store">
          <div className="footer-playstore-img">
            <a
              href="https://play.google.com/store/apps/details?id=com.purwalenta.purwalenta"
              target="_blank"
              rel="external nofollow noopener noreferrer"
            >
              <Image imgName="play-store.png" alt="play store" />
            </a>
          </div>
          <div className="footer-appstore-img">
            <a href="#">
              <Image imgName="app-store.png" alt="app store" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="copyright">
      © Copyright <b>Purwalenta</b> . All Rights Reserved
    </div>
  </Styles>
)

export default Footer
